import logo from './logo.svg';
import './App.css';
import './Media.css';
import React from 'react';

function ValidForm(props) {
    console.log(props);
    var dataValidReg = false;
    var objDataUser = {
        pass:"",
        mail:"",
    };

    function validRegForm(event){
        if(!dataValidReg){
            document.getElementById("reg").style.display = "flex";
            document.getElementById("valid").style.display = "none";
            dataValidReg = true;
        }else{
            document.getElementById("reg").style.display = "none";
            document.getElementById("valid").style.display = "flex";
            dataValidReg = false;
        }
    }
    
    function onChangePass(event) {
        objDataUser.pass = event.target.value;
        console.log(objDataUser.pass);
    }

    function onChangeEmail(event) {
        objDataUser.mail = event.target.value;
        console.log(objDataUser.mail);
    }

    function validForm(event){
        //console.log(event.target.getAttribute("data-valid"));
        if(event.target.getAttribute("data-valid") == "valid"){
            if( objDataUser.mail != "" && objDataUser.pass != ""){
                if( props.objAll[0].mail == objDataUser.mail && props.objAll[0].pass == objDataUser.pass){
                    window.location.href = "/main/";
                }
            }
        }
        //props.objAll[0].apiSess = "test";
        //console.log(props.objReg[0].apiSess);
    }

    let vc = 7;
    return (
        <div >

            <div class="jam-form" id="valid" style={{display: 'flex'}}>
                <div class="jam-form-control">
                    <div class="jam-form-head">
                        <div class="jam-h">
                            <span>Вход</span>
                        </div>
                    </div>
                    <div class="jam-form-control-input">
        
                        <div class="jam-form-input">
                            <label class="jam-span" >E-mail</label>
                            <input class="jam-input" type="email" onChange={onChangeEmail} />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >Пароль</label>
                            <input class="jam-input" type="password" onChange={onChangePass} />
                        </div>
                    </div>
                    <div class="jam-form-control-button">
                        <div class="jam-form-button">
                            <div data-valid="reg" class="jam-button" onClick={validRegForm}> <span class="jam-span-button">Не зарегистрирован</span></div>
                            <div data-valid="valid" class="jam-button" onClick={validForm}> <span class="jam-span-button">Войти</span></div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="jam-form" id="reg" style={{display: 'none'}}>
                <div class="jam-form-control">
                    <div class="jam-form-head">
                        <div class="jam-h">
                            <span>Регистрация</span>
                        </div>
                    </div>
                    <div class="jam-form-control-input">
                        <div class="jam-form-input">
                            <label class="jam-span">Имя</label>
                            <input class="jam-input" type="text" />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >Фамилия</label>
                            <input class="jam-input" type="text" />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >Отчество</label>
                            <input class="jam-input" type="text" />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >Телефон</label>
                            <input class="jam-input" type="phone" />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >E-mail</label>
                            <input class="jam-input" type="email" />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >Пароль</label>
                            <input class="jam-input" type="password" />
                        </div>
                        <div class="jam-form-input">
                            <label class="jam-span" >Повторите пароль</label>
                            <input class="jam-input" type="password" />
                        </div>
                    </div>
                    <div class="jam-form-control-button">
                        <div class="jam-form-button">
                            <div data-valid="reg" class="jam-button" onClick={validForm}> <span class="jam-span-button">Регистрация</span></div>
                            <div data-valid="valid" class="jam-button" onClick={validRegForm}> <span class="jam-span-button">Вход</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            );
   

}

export default ValidForm;
