import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';




import ValidRegForm from './ValidForm.js';
import Main from './Main.js';
import Roll from './Roll.js';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

var objAllTest = [{

    mail: "admin@admin.ru",
    pass:"test_pass",
}

];


var objAll = [{

    mail: "admin@admin.ru",
    pass:"test_pass",
}

];

var objMenu = [{
    name: "Заявки",
    url: "/main/",
},{
    name: "роли",
    url: "/roll/",
},{
    name: "Подразделения",
    url: "/subdiv/",
},{
    name: "Сотрудники",
    url: "/users/",
}

];


var objReg = [ {
    apiSess: "",
}
];

console.log("test");
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
<Router>
    <Routes>

        <Route path="/" element={<ValidRegForm objReg={objReg} objMenu={objMenu} objAll={objAll} />} />
        <Route path="/main" element={<Main objReg={objReg} objMenu={objMenu} objAll={objAll} />} />
        <Route path="/roll" element={<Roll objReg={objReg} objMenu={objMenu} objAll={objAll} />} />
    </Routes>
</Router> 
// {/* <Router>

//   <Routes>
//     <Route path="/" element={<App />} />
//     <Route path="/products" element={<Products />}>
//         <Route index element={<ProductsList />} />
//         <Route path=":id" element={<Product />} />
//     </Route>
//     <Route path="*" element={<h2>Ресурс не найден</h2>} />
//    </Routes>

// </Router> */}
);


