import logo from './logo.svg';
import './App.css';
import './Main.css';
import './Media.css';
import React from 'react';

function Main(props) {

    var objAllApi;
    let xhr = new XMLHttpRequest();
    //xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.open("GET", "https://elec-commerc.com/static/api/", false);
    //xhr.responseType = "json";
    try {
      xhr.send();
      if (xhr.status != 200) {
        console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`);
      } else {
        objAllApi = JSON.parse(xhr.response);
        //console.log(objAllApi);
        //console.log(JSON.parse(objAllApi));
      }
    } catch(err) { // для отлова ошибок используем конструкцию try...catch вместо onerror
        console.log("Запрос не удался");
    }

    function dataSetMenu(event){
        console.log(props.objReg[0].apiSess);
    }
    function dataSet(event){
        console.log(props.objReg[0].apiSess);
    }

    function dataMe(event){
        console.log(event.target);
        //setName(event.target.value);  
    }
    
    function dataExit(event) {

    }
    let getContent = objAllApi => {
        let content = [];
        for (let i = 0; i < objAllApi.length; i++) {
            const item = objAllApi[i];
            if(i == 0){
                content.push(             
                    <thead>
                        <tr>
                            <th>Номер заявки</th>
                            <th>Дата/Время регистрации</th>
                            <th>Постановщик</th>
                            <th>Тип заявки</th>
                            <th>Контрагаент</th>
                            <th>Номер и Дата договора</th>
                            <th>Заявитель</th>
                            <th>Должность заявителя</th>
                            <th>Телефон заявителя</th>
                            <th>Комментарий</th>
                            <th>Согласованный срок</th>
                            <th>Приоритет</th>
                            <th>Статус заявки</th>
                            <th>Назанченный исполнитель</th>
                        </tr>
                    </thead>
                );
            }else{
                content.push(
                    <tbody>
                        <tr>
                            <td data-main-id="id">12</td>
                            <td data-main-id="date" onClick={dataSet} >12.02.2024</td>
                            <td data-main-id="director" onClick={dataSet}>Тест Тест ТЕст</td>
                            <td data-main-id="typebid" onClick={dataSet}>Тест</td>
                            <td data-main-id="counteruser" onClick={dataSet}>Тест</td>
                            <td data-main-id="iddatecontract" onClick={dataSet}>Тест</td>
                            <td data-main-id="applicant" onClick={dataSet}>Тест</td>
                            <td data-main-id="jobtitleapplicant" onClick={dataSet}>Тест</td>
                            <td data-main-id="phoneapplicant" onClick={dataSet}>Тест</td>
                            <td data-main-id="comment" onClick={dataSet}>Тест</td>
                            <td data-main-id="agreedpart" onClick={dataSet}>Тест</td>
                            <td data-main-id="priority" onClick={dataSet}>Тест</td>
                            <td data-main-id="applicationstatus" onClick={dataSet}>Тест</td>
                            <td data-main-id="designatedexecutor" onClick={dataSet}>Тест</td>
                        </tr>
                    </tbody>
                );
            }
          
        }
        return content;
    }

    

    return (
    <div>
        <header class="jam-header">
            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" data-id="applications" onClick={dataSetMenu} > <span class="jam-span-button">Заявки</span></div> </li>
                <li class="jam-li-header"> <div class="jam-button" data-id="rolls" onClick={dataSetMenu} > <span class="jam-span-button">Роли</span></div> </li>
                <li class="jam-li-header"> <div class="jam-button" data-id="subdivision" onClick={dataSetMenu} > <span class="jam-span-button">Подразделения</span></div></li>
                <li class="jam-li-header"> <div class="jam-button" data-id="users" onClick={dataSetMenu} > <span class="jam-span-button">Сотрудники</span></div></li>
                <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
            </ul>
            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" data-id="me" onClick={dataMe} > <span class="jam-span-button">Имя Фамилия</span></div><img class="jam-img-icon-header" src="https://avatars.mds.yandex.net/i?id=4f7586d49edaa427e07a8819562fc284_l-5248434-images-thumbs&n=13" /></li> 
                <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <span class="jam-span-button">Выход</span></div> </li> 
            </ul>
    </header>
    <div class="jam-main">
        <div class="mobile-table">
            <table class="iksweb">
            {getContent(objAllApi)}

            </table>
            </div>
        </div>
            <div class="jam-bottom">
                <ul class="jam-ul-bottom">
                    <li class="jam-li-header"> <div class="jam-button" onClick={dataExit} > <span class="jam-span-button">Добавить</span></div> </li> 
                </ul>
            </div>

        <div>
            
        </div>
    </div>
    );
}

export default Main;
