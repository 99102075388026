import logo from './logo.svg';
import './App.css';
import './Roll.css';
import './Media.css';
import React from 'react';

function Roll() {
    // const [name, setName] = React.useState("Tom");
    // React.useEffect(() => {    
    //     // Изменяем заголовок html-страницы   
    //     document.title = `Привет ${name}`;  
    //   });
    function validRegForm(event){
        console.log(event.target);
        //setName(event.target.value);  
    }
    
    function onChangePass(event) {
        console.log(event);
        // var val = e.target.value;
        // this.setState({name: val});
    }

    function onChangeEmail(event) {
        console.log(event);
        // var val = e.target.value;
        // this.setState({name: val});
    }


    return (
    <div>
        <header class="jam-header">
            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" > <span class="jam-span-button">Заявки</span></div> </li>
                <li class="jam-li-header"> <div class="jam-button" > <span class="jam-span-button">Роли</span></div> </li>
                <li class="jam-li-header"> <div class="jam-button" > <span class="jam-span-button">Подразделения</span></div></li>
                <li class="jam-li-header"> <div class="jam-button" > <span class="jam-span-button">Сотрудники</span></div></li>
                <li class="jam-li-header">  <input class="jam-input" type="text" placeholder="поиск" /> </li>
            </ul>
            <ul class="jam-ul-header">
                <li class="jam-li-header"> <div class="jam-button" > <span class="jam-span-button">Имя Фамилия</span></div><img class="jam-img-icon-header" src="https://avatars.mds.yandex.net/i?id=4f7586d49edaa427e07a8819562fc284_l-5248434-images-thumbs&n=13" /></li> 
                <li class="jam-li-header"> <div class="jam-button" > <span class="jam-span-button">Выход</span></div> </li> 
            </ul>
    </header>
        <div class="jam-main">
           
        </div>
        
    </div>
    );
}

export default Roll;
